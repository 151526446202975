<template>
    <div>
        <header-menu ref="HeaderMenu"></header-menu>
        <div class="view-content">
            <div class="banner">
                <div class="desc__box text_left">
                    <h1 class="banner_title b_b_space font-size_60">
                        正也S2P推动数字化<br />管理转型
                    </h1>

                    <p class="banner_desc b_b_space font-size_20">
                        通过“管理+咨询+SaaS系统”的联动模式，为客户提供从传统营销管理提升到“智能化管理“，包含企业主数据的建立、清洗优化、业务流程及知识管理优化、打造数据分析体系，通过信息化建设战略布局与规划，切实帮助客户实现转型升级与价值落地。
                    </p>
                </div>
            </div>
            <div class="l_r_space t__space informatization_1">
                <h2 class="nav__title">传统医药企业信息化的现状</h2>
                <div style="padding-top: 0.7rem">
                    <a-row :gutter="140" type="flex">
                        <a-col
                            class="space_p_b-four"
                            :xs="12"
                            :sm="12"
                            :md="12"
                            :lg="12"
                            :xl="12"
                            :xxl="12"
                            v-for="(item, index) in statusList"
                            :key="index"
                        >
                            <div
                                :style="{
                                    background: `url(${item.img_url})`,
                                    width: item.width,
                                    height: item.height,
                                }"
                                class="bgz"
                            ></div>
                            <div class="text_left">
                                <h3 class="col_title1 b_b_space font-size_26">
                                    {{ item.title }}
                                </h3>
                                <p class="col_desc1 text_left font-size_16">
                                    {{ item.desc }}
                                </p>
                            </div>
                        </a-col>
                    </a-row>
                </div>
            </div>
            <div class="l_r_space t__space bgc_f4fbff informatization_2">
                <h2 class="nav__title">我们能帮您做什么</h2>
                <!-- <p class="nav__desc font-size_18" style="padding: 0.15rem 0 0.6rem 0">
          S2P综合平台针对药企信息化建设存在的问题，结合公司在药企信息化建设领域的实践成果及丰富经验，提出数字化药企的总体解决方案
        </p> -->
                <div class="tab_informatization" style="margin-top: 0.5rem">
                    <a-tabs
                        default-active-key="1"
                        :tabBarStyle="{
                            borderBottom: 'none',
                        }"
                    >
                        <a-tab-pane
                            key="1"
                            tab="销售管理的根本就是客户的分级分类 "
                        >
                            <div class="space_p_t-six">
                                <a-row :gutter="120" type="flex">
                                    <a-col
                                        class="space_p_b-four"
                                        :xs="24"
                                        :sm="12"
                                        :md="12"
                                        :lg="8"
                                        :xl="8"
                                        :xxl="8"
                                        v-for="(item, index) in helpList"
                                        :key="index"
                                    >
                                        <div
                                            :style="{
                                                background: `url(${item.img_url})`,
                                                width: '76px',
                                                height: '76px',
                                            }"
                                            class="bgz"
                                        ></div>
                                        <div>
                                            <h3
                                                class="col_title2 b_b_space font-size_24"
                                            >
                                                {{ item.title }}
                                            </h3>
                                            <p
                                                class="col_desc1 b_b_space text_left font-size_16"
                                            >
                                                {{ item.desc }}
                                            </p>
                                        </div>
                                    </a-col>
                                </a-row>
                            </div>
                        </a-tab-pane>
                        <a-tab-pane key="2" tab="流连宝—高效的流向采集服务">
                            <div class="space_p_t-six">
                                <a-row :gutter="120" type="flex">
                                    <a-col
                                        class="space_p_b-four"
                                        :xs="24"
                                        :sm="12"
                                        :md="12"
                                        :lg="12"
                                        :xl="12"
                                        :xxl="12"
                                        v-for="(item, index) in helpList1"
                                        :key="index"
                                    >
                                        <div
                                            :style="{
                                                background: `url(${item.img_url})`,
                                                width: '76px',
                                                height: '76px',
                                            }"
                                            class="bgz"
                                        ></div>
                                        <div>
                                            <h3
                                                class="col_title2 b_b_space font-size_24"
                                            >
                                                {{ item.title }}
                                            </h3>
                                            <p
                                                class="col_desc1 b_b_space text_left font-size_16"
                                            >
                                                {{ item.desc }}
                                            </p>
                                        </div>
                                    </a-col>
                                </a-row>
                            </div>
                        </a-tab-pane>
                        <a-tab-pane key="3" tab="彩蝶—多维度流向AI管理与分析">
                            <div class="space_p_t-six">
                                <a-row :gutter="120" type="flex">
                                    <a-col
                                        class="space_p_b-four"
                                        :xs="24"
                                        :sm="12"
                                        :md="12"
                                        :lg="6"
                                        :xl="6"
                                        :xxl="6"
                                        v-for="(item, index) in helpList2"
                                        :key="index"
                                    >
                                        <div
                                            :style="{
                                                background: `url(${item.img_url})`,
                                                width: '76px',
                                                height: '76px',
                                            }"
                                            class="bgz"
                                        ></div>
                                        <div>
                                            <h3
                                                class="col_title2 b_b_space font-size_24"
                                            >
                                                {{ item.title }}
                                            </h3>
                                            <p
                                                class="col_desc1 b_b_space text_left font-size_16"
                                            >
                                                {{ item.desc }}
                                            </p>
                                        </div>
                                    </a-col>
                                </a-row>
                            </div>
                        </a-tab-pane>

                        <a-tab-pane key="4" tab="多方式部署，灵活对接 ">
                            <div class="space_p_t-six">
                                <a-row :gutter="120" type="flex">
                                    <a-col
                                        class="space_p_b-four"
                                        :xs="24"
                                        :sm="12"
                                        :md="12"
                                        :lg="8"
                                        :xl="8"
                                        :xxl="8"
                                        v-for="(item, index) in helpList4"
                                        :key="index"
                                    >
                                        <div
                                            :style="{
                                                background: `url(${item.img_url})`,
                                                width: '76px',
                                                height: '76px',
                                            }"
                                            class="bgz"
                                        ></div>
                                        <div>
                                            <h3
                                                class="col_title2 b_b_space font-size_24"
                                            >
                                                {{ item.title }}
                                            </h3>
                                            <p
                                                class="col_desc1 b_b_space text_left font-size_16"
                                            >
                                                {{ item.desc }}
                                            </p>
                                        </div>
                                    </a-col>
                                </a-row>
                            </div>
                        </a-tab-pane>
                    </a-tabs>
                </div>
            </div>
            <div class="l_r_space t__space informatization_3">
                <h2 class="nav__title">S2P优势</h2>
                <p
                    class="nav__desc font-size_24"
                    style="padding: 0.15rem 0 0.7rem 0"
                >
                    S2P综合平台将数据与业务有效的进行了切分，使药企摆脱原先由个性化以及多变的业务系统引起的数据混乱现状
                </p>
                <a-row :gutter="120" type="flex">
                    <a-col
                        class="space_p_b-four"
                        :xs="24"
                        :sm="12"
                        :md="12"
                        :lg="8"
                        :xl="8"
                        :xxl="8"
                        v-for="(item, index) in advantageList"
                        :key="index"
                    >
                        <div
                            :style="{
                                background: `url(${item.img_url})`,
                                width: item.width,
                                height: item.height,
                            }"
                            class="bgz"
                        ></div>
                        <div>
                            <h3 class="col_title3 b_b_space font-size_26">
                                {{ item.title }}
                            </h3>
                            <p
                                class="col_desc1 font-size_16"
                                style="text-align: center"
                            >
                                {{ item.desc }}
                            </p>
                        </div>
                    </a-col>
                </a-row>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
import Footer from '@/components/Footer'
import HeaderMenu from '@/components/HeaderMenu'
import informatization_1 from '@/assets/img/png/informatization_1.png'
import informatization_2 from '@/assets/img/png/informatization_2.png'
import informatization_3 from '@/assets/img/png/informatization_3.png'
import informatization_4 from '@/assets/img/png/informatization_4.png'
import informatization_5 from '@/assets/img/png/informatization_5.png'
import informatization_6 from '@/assets/img/png/informatization_6.png'
import informatization_7 from '@/assets/img/png/informatization_7.png'
import informatization_8 from '@/assets/img/png/informatization_8.png'
import informatization_9 from '@/assets/img/png/informatization_9.png'
import informatization_15 from '@/assets/img/png/informatization_15.png'
import informatization_10 from '@/assets/img/png/informatization_10.png'
import informatization_11 from '@/assets/img/png/informatization_11.png'
import informatization_12 from '@/assets/img/png/informatization_12.png'
import informatization_13 from '@/assets/img/png/informatization_13.png'
import informatization_14 from '@/assets/img/png/informatization_14.png'
export default {
    name: 'informatization',
    beforeCreate() {},
    components: { Footer, HeaderMenu },
    data() {
        return {
            statusList: [
                {
                    title: '推动“传统销售”向“专业化学术化推广转型”',
                    width: '76px',
                    height: '79px',
                    desc:
                        '药企要从以往的“以销售为导向”转向做“学术推广”，国内药企代表大多并非医药科班背景，看不懂说明书听不懂临床术语，迫于公司压力把产品说明书倒背如流，和医生开展学术式话题障碍重重。',
                    img_url: informatization_5,
                },
                {
                    title: '推动“粗放式管理”向“精细化管理”转型',
                    width: '87px',
                    height: '81px',
                    desc:
                        '营销管理粗放，无法区分哪些是有效客户，哪些是无效客户；也无法区分重点客户与非重点客户，增长点在哪里等问题。',
                    img_url: informatization_4,
                },
                {
                    title: '推动“管理软件化”向“快速迭代化”转型',
                    width: '83px',
                    height: '79px',
                    desc:
                        '传统软件大部分是“买断制”，部署完成后很少更新迭代或迭代缓慢，对于业务的变化不能灵活的应对，使得业务反而受制于工具。',
                    img_url: informatization_6,
                },
                {
                    title: '推动“部门信息孤岛”向“企业多系统权限对接”转型',
                    width: '75px',
                    height: '75px',
                    desc:
                        '企业根据不同的业务需求上了很多的系统，但是系统之间有壁垒，数据无法打通或打通困难。',
                    img_url: informatization_1,
                },
            ],
            helpList: [
                {
                    title: '海量行业数据',
                    desc:
                        '正也科技S2P基于行业多年的经验及通过网络公共渠道获取到了海量的行业主数据，通过AI智能算法对数据进行清洗校验，S2P平台上沉淀了100万计实名医生、3万+医院、49万+基层医疗、52万+药店、8千+连锁总部、8千+配送商。',
                    img_url: informatization_7,
                },
                {
                    title: '客户标签',
                    desc:
                        '根据企业的不同的营销业务模式，针对不同的客户类型，可进行分类标签自定义，例如：医院等级、重点学科、院校附属、社区药店、院边药房、学校边店、商超药店等多标签。',
                    img_url: informatization_8,
                },
                {
                    title: '客户分级标准',
                    desc:
                        '营销模式不同，业务所面对的的客户分级标准也有不同，比如：不同级别的医院机构、各级KOL、连锁等级、内部等级、代理商等级等不同的客户分级机制。从潜力值及支持度全方位维度帮助企业为客户定级，快速定位重点客户和潜力客户。',
                    img_url: informatization_9,
                },
            ],
            helpList1: [
                {
                    title: '流向采集',
                    desc:
                        '目前企业对商业流向的获取方式分网站登录下载、邮件或者人工打印。正也S2P通过技术革新，不仅支持DDI插件直连自动采集流向，也支持网站账密采集，并且账密采集流向成功率达到99%以上。基于对行业的趋势，“账密采集方式”已经成为趋势。',
                    img_url: informatization_7,
                },
                {
                    title: '流向服务运行监控',
                    desc:
                        '流向采集服务支持日/周/月流向，包含商业公司的进销存数据。使用平台自动化管理，对于采集异常数据可以做到有效验证与实时监控。',
                    img_url: informatization_8,
                },
            ],
            helpList2: [
                {
                    title: '数据清洗',
                    desc:
                        '基于AI智能算法，建立模板、客户等之间的映射关系及清洗规则，帮助企业快速大量清洗流向数据，形成企业自有的独立清洗规则。',
                    img_url: informatization_7,
                },
                {
                    title: '客户/产品别名库',
                    desc:
                        '包括：产品、商业公司、医疗机构、连锁门店、单店、诊所等。基于每次的流向清洗，企业自主别名库，在不停地完善与积累，帮助企业建立属于企业自有的主数据库。',
                    img_url: informatization_8,
                },
                {
                    title: '流向申诉',
                    desc:
                        '流向数据可按权限分发，业务人员异议，可通过申诉流程发起“流向申述”，帮助企业及时核对问题数据，迅速纠正，协助准确决策。',
                    img_url: informatization_9,
                },
                {
                    title: '流向报表分析',
                    desc:
                        '基于流向清洗-->确认-->最终入库系统AI分析，可自动生成各类数据的智能分析报表。',
                    img_url: informatization_15,
                },
            ],
            helpList4: [
                {
                    title: '部署方式',
                    desc:
                        '平台采用SaaS模式，同时支持本地部署，私有云部署。敏捷开发团队，可根据业务变化情况快速响应，灵活应对各种业务场景。',
                    img_url: informatization_7,
                },
                {
                    title: '标准API接口',
                    desc:
                        'S2P提供标准化API接口，与企业内部已使用系统进行系统互联，数据互通。解决企业内部多套系统，信息不共享问题。',
                    img_url: informatization_8,
                },
                {
                    title: '打通药企闭环',
                    desc:
                        '主数据系统、辖区管理系统、行为管理系统、流向管理系统、学习平台、奖金绩效，全方位打造药企信息化闭环产业。',
                    img_url: informatization_9,
                },
            ],
            advantageList: [
                {
                    title: '高性能',
                    width: '73px',
                    height: '88px',
                    desc:
                        'S2P融入了多进程、全维度管理，在系统架构上完全分散了性能消耗的瓶颈，从而在本质上提高了系统的处理效率，并且能支持超高并发。',
                    img_url: informatization_10,
                },
                {
                    title: '高可靠',
                    width: '72px',
                    height: '78px',
                    desc:
                        'S2P采用取了SEDA、NIO等业界先进的技术以及松散的集群部署方式来保障平台整体基础设施以及关键服务的可靠性，从而提高了平台的容错性。',
                    img_url: informatization_11,
                },
                {
                    title: '高扩展',
                    width: '69px',
                    height: '71px',
                    desc:
                        'S2P在设计之初就有针对地在技术、业务、产业环境的特点的基础上重点提升了产品的扩展性，提供了有针对性的、开放的API接口。',
                    img_url: informatization_12,
                },
                {
                    title: '业务化',
                    width: '79px',
                    height: '73px',
                    desc:
                        'S2P是企业内部重要的基础设施，其日常的有效管理对提高工作效率和降低运营风险具有重要的意义。',
                    img_url: informatization_13,
                },
                {
                    title: '个性化',
                    width: '72px',
                    height: '77px',
                    desc:
                        '药企因其产业环境、业务氛围等因素的不同，导致其集成的方式方法不可在企业间复制，S2P支持本地化部署、二次开发服务从而更加贴切满足客户需求。',
                    img_url: informatization_14,
                },
            ],
        }
    },
    created() {},
    methods: {},
}
</script>
<style scoped lang="scss">
@import '@/assets/style/color.scss';
.col_title1 {
    padding: 0.25rem 0 0.12rem 0;
    color: $color-darkBlue;
    text-align: center;
}
.col_desc1 {
    color: $color-fixGray;
    margin-bottom: 0;
}
.col_title2 {
    padding: 0.15rem 0 0.2rem 0;
    font-weight: bold;
}
.col_title3 {
    padding: 0.3rem 0 0.12rem 0;
    color: $color-darkMinBlue;
    font-weight: bold;
}
.banner {
    width: 100%;
    position: relative;
    background: url('~@/assets/img/png/informatization_banner.png');
    background-size: 100% 100%;
    height: 8rem;
    overflow: hidden;
    .desc__box {
        position: absolute;
        top: 1.5rem;
        left: 13.5%;
        .banner_title {
            color: $color-lightBlue;
        }
        .banner_desc {
            width: 5.9rem;
            padding-top: 0.45rem;
            margin-bottom: 0;
        }
    }
}
.informatization_1 {
    padding-bottom: 0.1rem;
}
.informatization_2 {
    padding-bottom: 0.7rem;
}
.informatization_3 {
    padding-bottom: 0.1rem;
}
.bgz {
    background-size: 100% 100%;
    margin: 0 auto;
}
.tab_informatization {
    // /deep/ .ant-tabs-bar {
    //   border-bottom: none;
    //   .ant-tabs-nav-scroll {
    //     // color: $color-fixGray;
    //     // font-size: 0.26rem;
    //     .ant-tabs-nav .ant-tabs-tab-active {
    //       color: $color-darkBlue;
    //       font-weight: bold;
    //     }
    //   }
    // }
}
.space_p_b-four {
    padding-bottom: 0.4rem;
}
.space_p_t-six {
    padding-top: 0.6rem;
}
</style>
