var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header-menu',{ref:"HeaderMenu"}),_c('div',{staticClass:"view-content"},[_vm._m(0),_c('div',{staticClass:"l_r_space t__space informatization_1"},[_c('h2',{staticClass:"nav__title"},[_vm._v("传统医药企业信息化的现状")]),_c('div',{staticStyle:{"padding-top":"0.7rem"}},[_c('a-row',{attrs:{"gutter":140,"type":"flex"}},_vm._l((_vm.statusList),function(item,index){return _c('a-col',{key:index,staticClass:"space_p_b-four",attrs:{"xs":12,"sm":12,"md":12,"lg":12,"xl":12,"xxl":12}},[_c('div',{staticClass:"bgz",style:({
                                background: ("url(" + (item.img_url) + ")"),
                                width: item.width,
                                height: item.height,
                            })}),_c('div',{staticClass:"text_left"},[_c('h3',{staticClass:"col_title1 b_b_space font-size_26"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('p',{staticClass:"col_desc1 text_left font-size_16"},[_vm._v(" "+_vm._s(item.desc)+" ")])])])}),1)],1)]),_c('div',{staticClass:"l_r_space t__space bgc_f4fbff informatization_2"},[_c('h2',{staticClass:"nav__title"},[_vm._v("我们能帮您做什么")]),_c('div',{staticClass:"tab_informatization",staticStyle:{"margin-top":"0.5rem"}},[_c('a-tabs',{attrs:{"default-active-key":"1","tabBarStyle":{
                        borderBottom: 'none',
                    }}},[_c('a-tab-pane',{key:"1",attrs:{"tab":"销售管理的根本就是客户的分级分类 "}},[_c('div',{staticClass:"space_p_t-six"},[_c('a-row',{attrs:{"gutter":120,"type":"flex"}},_vm._l((_vm.helpList),function(item,index){return _c('a-col',{key:index,staticClass:"space_p_b-four",attrs:{"xs":24,"sm":12,"md":12,"lg":8,"xl":8,"xxl":8}},[_c('div',{staticClass:"bgz",style:({
                                            background: ("url(" + (item.img_url) + ")"),
                                            width: '76px',
                                            height: '76px',
                                        })}),_c('div',[_c('h3',{staticClass:"col_title2 b_b_space font-size_24"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('p',{staticClass:"col_desc1 b_b_space text_left font-size_16"},[_vm._v(" "+_vm._s(item.desc)+" ")])])])}),1)],1)]),_c('a-tab-pane',{key:"2",attrs:{"tab":"流连宝—高效的流向采集服务"}},[_c('div',{staticClass:"space_p_t-six"},[_c('a-row',{attrs:{"gutter":120,"type":"flex"}},_vm._l((_vm.helpList1),function(item,index){return _c('a-col',{key:index,staticClass:"space_p_b-four",attrs:{"xs":24,"sm":12,"md":12,"lg":12,"xl":12,"xxl":12}},[_c('div',{staticClass:"bgz",style:({
                                            background: ("url(" + (item.img_url) + ")"),
                                            width: '76px',
                                            height: '76px',
                                        })}),_c('div',[_c('h3',{staticClass:"col_title2 b_b_space font-size_24"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('p',{staticClass:"col_desc1 b_b_space text_left font-size_16"},[_vm._v(" "+_vm._s(item.desc)+" ")])])])}),1)],1)]),_c('a-tab-pane',{key:"3",attrs:{"tab":"彩蝶—多维度流向AI管理与分析"}},[_c('div',{staticClass:"space_p_t-six"},[_c('a-row',{attrs:{"gutter":120,"type":"flex"}},_vm._l((_vm.helpList2),function(item,index){return _c('a-col',{key:index,staticClass:"space_p_b-four",attrs:{"xs":24,"sm":12,"md":12,"lg":6,"xl":6,"xxl":6}},[_c('div',{staticClass:"bgz",style:({
                                            background: ("url(" + (item.img_url) + ")"),
                                            width: '76px',
                                            height: '76px',
                                        })}),_c('div',[_c('h3',{staticClass:"col_title2 b_b_space font-size_24"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('p',{staticClass:"col_desc1 b_b_space text_left font-size_16"},[_vm._v(" "+_vm._s(item.desc)+" ")])])])}),1)],1)]),_c('a-tab-pane',{key:"4",attrs:{"tab":"多方式部署，灵活对接 "}},[_c('div',{staticClass:"space_p_t-six"},[_c('a-row',{attrs:{"gutter":120,"type":"flex"}},_vm._l((_vm.helpList4),function(item,index){return _c('a-col',{key:index,staticClass:"space_p_b-four",attrs:{"xs":24,"sm":12,"md":12,"lg":8,"xl":8,"xxl":8}},[_c('div',{staticClass:"bgz",style:({
                                            background: ("url(" + (item.img_url) + ")"),
                                            width: '76px',
                                            height: '76px',
                                        })}),_c('div',[_c('h3',{staticClass:"col_title2 b_b_space font-size_24"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('p',{staticClass:"col_desc1 b_b_space text_left font-size_16"},[_vm._v(" "+_vm._s(item.desc)+" ")])])])}),1)],1)])],1)],1)]),_c('div',{staticClass:"l_r_space t__space informatization_3"},[_c('h2',{staticClass:"nav__title"},[_vm._v("S2P优势")]),_c('p',{staticClass:"nav__desc font-size_24",staticStyle:{"padding":"0.15rem 0 0.7rem 0"}},[_vm._v(" S2P综合平台将数据与业务有效的进行了切分，使药企摆脱原先由个性化以及多变的业务系统引起的数据混乱现状 ")]),_c('a-row',{attrs:{"gutter":120,"type":"flex"}},_vm._l((_vm.advantageList),function(item,index){return _c('a-col',{key:index,staticClass:"space_p_b-four",attrs:{"xs":24,"sm":12,"md":12,"lg":8,"xl":8,"xxl":8}},[_c('div',{staticClass:"bgz",style:({
                            background: ("url(" + (item.img_url) + ")"),
                            width: item.width,
                            height: item.height,
                        })}),_c('div',[_c('h3',{staticClass:"col_title3 b_b_space font-size_26"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('p',{staticClass:"col_desc1 font-size_16",staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(item.desc)+" ")])])])}),1)],1)]),_c('Footer')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"banner"},[_c('div',{staticClass:"desc__box text_left"},[_c('h1',{staticClass:"banner_title b_b_space font-size_60"},[_vm._v(" 正也S2P推动数字化"),_c('br'),_vm._v("管理转型 ")]),_c('p',{staticClass:"banner_desc b_b_space font-size_20"},[_vm._v(" 通过“管理+咨询+SaaS系统”的联动模式，为客户提供从传统营销管理提升到“智能化管理“，包含企业主数据的建立、清洗优化、业务流程及知识管理优化、打造数据分析体系，通过信息化建设战略布局与规划，切实帮助客户实现转型升级与价值落地。 ")])])])}]

export { render, staticRenderFns }